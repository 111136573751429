import React from "react";

import { useGetReportByIdQuery } from "../../apis/Report";
import { useCrawlerContext } from "../../contexts/CrawlerContext";

import { Flex, Space, Table, Tag, Badge, notification, Button, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';

import type { GetProp, TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';

import { ReportResponse } from "../../interfaces/ReportResponse";
import { AxiosError } from "axios";

import Highlighter from 'react-highlight-words';

import ReportToolbar from "./ReportToolbar";

import { createStyles } from "antd-style";

type DataIndex = keyof ReportResponse;

const useStyles = createStyles(({ token }) => ({
    header: {
        color: token.colorPrimary,
        fontWeight: 'bold',
        margin: '0'
    },
    searchIcon: {
        color: token.colorPrimary,
    }
}));

type ColumnsType<T extends object> = GetProp<TableProps<T>, 'columns'>;

const ReportDetailView: React.FC = () => {
    const { uploadResult } = useCrawlerContext();
    const [notifyApi, contextHolder] = notification.useNotification();

    const [enableAutoRefresh, setEnableAutoRefresh] = React.useState<boolean>(false);
    const [refreshInterval, setRefreshInterval] = React.useState<number>(60);
    const { data, isError, error, refetch, isFetching } = useGetReportByIdQuery(uploadResult?.importId, enableAutoRefresh, refreshInterval);

    const [searchText, setSearchText] = React.useState('');
    const [searchedColumn, setSearchedColumn] = React.useState('');
    const searchInput = React.useRef<InputRef>(null);

    const { styles } = useStyles();

    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: DataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm']) => {
        setSearchText('');
        clearFilters();
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<ReportResponse> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <Space direction="vertical" style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search text`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="link"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </Space>
        ),

        filterIcon: (filtered: boolean) => (
            <SearchOutlined className={filtered ? styles.searchIcon : undefined} />
        ),

        onFilter: (value, record) => {
            var result = record[dataIndex]?.toString().toLowerCase().includes((value as string).toLowerCase());
            return result ?? false;
        },

        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },

        render: (text) =>
            searchedColumn === dataIndex ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
                : text
    });

    React.useEffect(() => {
        if (isError) {
            const convert = error as AxiosError;
            notifyApi.open({
                message: 'Get report failed',
                description: convert.response?.data as string,
                type: 'error',
                placement: 'bottomRight',
            });
        }
    }, [isError, error, notifyApi]);

    const uniqueSuppliers = data?.reduce((acc: string[], obj: ReportResponse) => {
        if (!acc.includes(obj.supplierName)) {
            acc.push(obj.supplierName);
        }
        return acc.sort();
    }, []);

    const uniqueStatus = data?.reduce((acc: string[], obj: ReportResponse) => {
        if (!acc.includes(obj.currentState)) {
            acc.push(obj.currentState);
        }
        return acc.sort();
    }, []);

    const columns: ColumnsType<ReportResponse> = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'name',
            fixed: 'left',
            sorter: (a, b) => a.productName.localeCompare(b.productName),
            ...getColumnSearchProps('productName'),
        },
        {
            title: 'Product Code',
            dataIndex: 'productId',
            key: 'code',
            fixed: 'left',
            sorter: (a, b) => a.productId.localeCompare(b.productId),
            ...getColumnSearchProps('productId'),
        },
        {
            title: 'Supplier',
            dataIndex: 'supplierName',
            key: 'supplier',
            fixed: 'left',
            filters: uniqueSuppliers?.map((supplier) => {
                return { text: supplier, value: supplier };
            }),
            sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
            onFilter: (value, record) => record.supplierName === value,
        },
        {
            title: 'Crawler Status',
            dataIndex: 'currentState',
            key: 'status',
            fixed: 'left',
            filters: uniqueStatus?.map((status) => {
                return { text: status, value: status };
            }),
            onFilter: (value, record) => record.currentState === value,
        },
        {
            title: 'Download Result',
            dataIndex: 'downloadedResult',
            key: 'download',
            fixed: 'left',
            render: (_, record: ReportResponse) => {

                if (record.downloadedError) {
                    return <p>{record.downloadedError}</p>
                }

                if (record.downloadedData.length === 0) {
                    return <p></p>
                }

                return record.downloadedData.map((d) => {
                    return (
                        <Tag key={d.language} color='blue' style={{ margin: '2px' }}>
                            {d.language.toLowerCase()}
                        </Tag>
                    )
                })

            },
        },
        {
            title: 'Parse Result',
            dataIndex: 'parsedResult',
            key: 'parse',
            fixed: 'left',
            render: (_, record: ReportResponse) => {

                if (record.parsedError) {
                    return <>{record.parsedError}</>
                }

                if (!record.parsedData) {
                    return <></>
                }

                const success = record.parsedData.totalCount - record.parsedData.errorCount;
                const status = record.parsedData.errorCount === 0 ? 'success' : (record.parsedData.errorCount === record.parsedData.totalCount ? 'error' : 'warning');

                return (
                    <Badge
                        status={status}
                        text={`${success} / ${record.parsedData.totalCount}`}
                    />
                )

            },
        },
        {
            title: 'Validate Result',
            dataIndex: 'validateResult',
            key: 'validate',
            fixed: 'left',
            render: (_, record: ReportResponse) => {

                if (record.validatedError) {
                    return <>{record.validatedError}</>
                }

                if (record.validatedData.length === 0) {
                    return <></>
                }

                const successCount = record.validatedData.filter((d) => d.status.toLowerCase() === 'success').length;
                const outdateCount = record.validatedData.filter((d) => d.status.toLowerCase() === 'outdate').length;
                const rejectCount = record.validatedData.filter((d) => d.status.toLowerCase() === 'reject').length;

                return <Space direction="vertical">
                    <Badge status="success" text={`Success: ${successCount}`} />
                    <Badge status="warning" text={`Outdate: ${outdateCount}`} />
                    <Badge status="error" text={`Reject: ${rejectCount}`} />
                </Space>

            },
        },
        {
            title: 'Execution Time',
            dataIndex: 'time',
            key: 'time',
            fixed: 'left',
            render: (_, record: ReportResponse) => {

                if (record.completedAt === null) {
                    return 'Running...';
                }

                var time = new Date(record.completedAt).getTime() - new Date(record.startedAt).getTime();
                return (time / 1000).toFixed(1) + ' s';

            }
        }
    ];

    return (
        <Flex vertical={true} gap="middle" className="report-con">
            {contextHolder}

            <ReportToolbar
                refresh={refetch}
                loading={isFetching}
                enableAutoRefresh={enableAutoRefresh}
                setEnableAutoRefresh={setEnableAutoRefresh}
                refreshInterval={refreshInterval}
                setRefreshInterval={setRefreshInterval}
            />

            <Table<ReportResponse>
                dataSource={data}
                columns={columns}
                loading={isFetching}
                bordered={true}
                pagination={{ pageSize: 20, showTotal: (total) => `Total ${total} items` }}
                rowKey="correlationId"
                scroll={{ y: 'calc(100vh - 250px)' }}
            />
        </Flex>

    );
};

export default ReportDetailView;